/**
 * Created by @author Søren Tramm
 */
import React, { useState } from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import DropdownIcon from '../assets/dropdown.svg'
import { useMediaQuery } from 'react-responsive'

const ITEMS_TOTAL_COUNT = 14
export const WouldYouLikeToKnowMore = () => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const [isCollapsed, setIsCollapsed] = useState(true)

  function renderList() {
    const data = Array.from({ length: ITEMS_TOTAL_COUNT })
    return (
      <div>
        {data.map((item, index) => {
          return (
            <CollapsableItem
              title={t('wouldYouLikeToKnowMore.subheadline' + (index + 1))}
              body={t('wouldYouLikeToKnowMore.text' + (index + 1))}
              key={index}
            />
          )
        })}
      </div>
    )
  }

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed)
  }

  const styles = {
    listOpen: {
      maxHeight: '100%',
      overflow: 'hidden',
    },
    listClose: {
      maxHeight: '250px',
      overflow: 'hidden',
    },
    gradient: {
      // background: 'linear-gradient(to bottom, #FF000066 0%, #e3ebfaff 90%)',
      background: 'linear-gradient(to bottom, #E8E8E866 0%, #E8E8E8FF 90%)',
      marginTop: isMobile ? '-90px' : '-60px',
      zIndex: '10',
    },
    show: {
      display: 'block',
    },
    hide: {
      display: 'none',
    },
    button: {
      minWidth: '200px',
    },
  }

  const gradientCombinedStyle = {
    ...styles.gradient,
    ...(isCollapsed ? styles.show : styles.hide),
  }

  return (
    <div className="container py-7">
      {/*HEADLINE*/}
      <div className="row" style={{}}>
        <div className="col-12 my-0 my-md-2">
          {/* Whats In It For You */}
          <h4 className="mb-5 mt-0 mt-md-4 text-center text-sm-left">{t('wouldYouLikeToKnowMore.headline')}</h4>
        </div>
      </div>

      {/*LIST*/}
      <div className="row">
        <div className="col-12 my-0 my-md-2 position-relative" style={isCollapsed ? styles.listClose : styles.listOpen}>
          <div className="row" style={isCollapsed ? styles.listClose : styles.listOpen}>
            <div className="col-12 my-0 my-md-2 ">
              <div>{renderList()}</div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-8 mx-auto " style={gradientCombinedStyle}></div>
      </div>

      {/*BUTTON*/}
      <div className="row mt-6">
        <div className="col">
          <div className="d-flex w-100 w-lg-auto justify-content-center align-items-center mb-2">
            <button
              onClick={handleToggle}
              className="btn btn-primary px-4 py-3 px-md-6 mb-3 mb-md-0 me-md-4"
              style={styles.button}
            >
              {isCollapsed ? t('wouldYouLikeToKnowMore.showmoreButton') : t('wouldYouLikeToKnowMore.showlessButton')}{' '}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const CollapsableItem = ({ title, body }) => {
  const [isCollapsed, setIsCollapsed] = useState(true)

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed)
  }

  const collapsibleStyles = {
    height: isCollapsed ? 0 : 'auto',
    transition: 'height 0.33s ease-in-out',
    overflow: 'hidden',
  }

  const styles = {
    container: {
      // borderBottom: '1px solid rgba(0, 0, 0, 0.6)',
    },
    inner: {
      borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
    },
    button: {
      border: 'none',
      background: 'none',
      color: 'inherit',
      cursor: 'pointer',
      padding: 0,
    },
    icon: {
      marginTop: 4,
      width: '1.5rem',
      height: '1.5rem',
    },
    turn: {
      transform: 'rotate(180deg)',
    },
  }

  return (
    <div className="row" style={styles.container}>
      <div className="mx-auto col-12 col-md-8" style={styles.inner}>
        <div className="row my-3" onClick={handleToggle} style={styles.button}>
          <div className="col-11 d-flex justify-content-start">
            <div className="col-10">
              <p className="p-large-bold" dangerouslySetInnerHTML={{ __html: title }} />
              {/*<p className="p-large-bold">{title}</p>*/}
            </div>
          </div>
          <div className="col-1 d-flex justify-content-end">
            <div style={!isCollapsed ? styles.turn : {}}>
              <DropdownIcon style={styles.icon}></DropdownIcon>
            </div>
          </div>
        </div>
        <div style={collapsibleStyles}>
          <div className="row" style={{}}>
            <div className="col-12 mb-3">
              <p dangerouslySetInnerHTML={{ __html: body }} />
              {/*<p>{body}</p>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

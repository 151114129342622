/**
 * Created by @author Søren Tramm
 */
import { useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useEffect } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useMediaQuery } from 'react-responsive'

export const JoinTheStudyAtYourConvenience = () => {
  const { t } = useTranslation()

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-6 mx-auto text-center pe-6 pe-md-2 pe-lg-4 pe-xl-6">
          {/*MAP - DESKTOP*/}
          <div className="d-md-block d-none">
            <Map></Map>
          </div>
        </div>

        {/*TEXT*/}
        <div className="col-12 col-md-6 mx-auto text-center text-md-start ps-0 ps-md-2 ps-lg-4 ps-xl-6">
          <div className="row">
            <div className="col-12 order-1">
              <div>
                <h4>{t('joinTheStudyAtYourConvenience.headline')}</h4>
              </div>
              <div className="my-5 my-md-1">
                <p>{t('joinTheStudyAtYourConvenience.text2')}</p>
              </div>
            </div>
            <div className="col-12 order-2 mb-4">
              {/*MAP - MOBILE*/}
              <div className="d-block d-md-none">
                <MapMobile></MapMobile>
              </div>
            </div>
            <div className="col-12 order-3 order-md-4 mt-5 mt-md-1">
              <div>
                <p className="p-large-bold">{t('joinTheStudyAtYourConvenience.subheadline1')}</p>
              </div>
              <div className="mt-3 mt-md-1">
                <p style={{ fontSize: '1rem' }}>{t('joinTheStudyAtYourConvenience.text')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Map = () => {
  const { t } = useTranslation()

  const [fontSizeMap, setFontSizeMap] = React.useState('1rem')

  // Media queries
  const xxs = useMediaQuery({ maxWidth: 319 })
  const xs = useMediaQuery({ minWidth: 320, maxWidth: 575 })
  const sm = useMediaQuery({ minWidth: 576, maxWidth: 767 })
  const md = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  const lg = useMediaQuery({ minWidth: 992 })

  useEffect(() => {
    // xxs
    if (xxs) {
      setFontSizeMap('0.5rem')
      // xs
    } else if (xs) {
      setFontSizeMap('0.6rem')
      // sm
    } else if (sm) {
      setFontSizeMap('0.75rem')
      // md
    } else if (md) {
      setFontSizeMap('0.6rem')
      // lg
    } else if (lg) {
      setFontSizeMap('0.85rem')
    }
  }, [xxs, xs, sm, md, lg])

  // console.log(`xxs ${xxs}, xs ${xs}, sm ${sm}, md ${md}, lg ${lg} : `, getFontSizeMap())
  // console.log('fontSizeMap: ', fontSizeMap)

  const generateColumn = (start: number, end: number) => {
    const liOutput = []
    for (let i = start; i <= end; i++) {
      liOutput.push(
        <li className="list-group-item" style={styles.li} key={i}>
          {t('states.' + i)}
        </li>
      )
    }

    return (
      <div className="col-4" style={styles.wrapper}>
        <ul className="list-group" style={styles.ul}>
          {liOutput}
        </ul>
      </div>
    )
  }

  const styles = {
    container: {
      width: 'inherit',
    },
    inner: {
      margin: 0,
    },
    wrapper: {},
    ul: {
      listStyle: 'circle',
      padding: 0,
    },
    li: {
      fontSize: fontSizeMap,
      fontWeight: 700,
    },
  }
  return (
    <div className="d-flex align-items-center justify-content-center position-relative " style={styles.container}>
      <StaticImage src="../images/usa-map.png" alt="map" placeholder="blurred" layout="constrained" />
      <div className="position-absolute text-start start-0 end-0 ps-4 ps-sm-4 ps-md-4 ps-lg-5" style={styles.inner}>
        <div className="row">
          {generateColumn(1, 12)}
          {generateColumn(13, 24)}
          {generateColumn(25, 37)}
        </div>
      </div>
    </div>
  )
}

const MapMobile = () => {
  const { t } = useTranslation()

  const [fontSizeMap, setFontSizeMap] = React.useState('1rem')

  // Media queries
  const xxs = useMediaQuery({ maxWidth: 319 })
  const xs = useMediaQuery({ minWidth: 320, maxWidth: 575 })
  const sm = useMediaQuery({ minWidth: 576, maxWidth: 767 })
  const md = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  const lg = useMediaQuery({ minWidth: 992 })

  useEffect(() => {
    // xxs
    if (xxs) {
      setFontSizeMap('0.60rem')
      // xs
    } else if (xs) {
      setFontSizeMap('0.70rem')
      // sm
    } else if (sm) {
      setFontSizeMap('0.85rem')
      // md
    } else if (md) {
      setFontSizeMap('0.6rem')
      // lg
    } else if (lg) {
      setFontSizeMap('0.85rem')
    }
  }, [xxs, xs, sm, md, lg])

  // console.log(`xxs ${xxs}, xs ${xs}, sm ${sm}, md ${md}, lg ${lg} : `, getFontSizeMap())
  // console.log('fontSizeMap: ', fontSizeMap)

  const generateColumn = (list) => {
    const liOutput = []
    for (let i = 0; i < list.length; i++) {
      liOutput.push(
        <li className="list-group-item" style={styles.li} key={i}>
          {t('states.' + list[i])}
        </li>
      )
    }

    return (
      <div className="col-6" style={styles.wrapper}>
        <ul className="list-group" style={styles.ul}>
          {liOutput}
        </ul>
      </div>
    )
  }

  const styles = {
    container: {
      width: 'inherit',
    },
    inner: {
      margin: 0,
    },
    wrapper: {},
    ul: {
      listStyle: 'circle',
      padding: 0,
    },
    li: {
      fontSize: fontSizeMap,
      fontWeight: 700,
    },
  }
  return (
    <div className="d-flex align-items-center justify-content-center position-relative " style={styles.container}>
      <StaticImage src="../images/usa-map.png" alt="map" placeholder="blurred" layout="constrained" />
      <div className="position-absolute text-start start-0 end-0 ps-4 ps-sm-4 ps-md-4 ps-lg-5" style={styles.inner}>
        <div className="row">
          {generateColumn([1, 3, 5, 8, 9, 10, 11, 12, 14, 16, 17, 18, 19])}
          {generateColumn([21, 22, 23, 25, 26, 27, 29, 30, 31, 33, 34])}
        </div>
      </div>
    </div>
  )
}

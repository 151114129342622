/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 31/08/2022.
 */
import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'
import ClockSvg from '../assets/clock.svg'
import VerticalDots from '../assets/vertical-dots.svg'
import { useMediaQuery } from 'react-responsive'

export const HowToParticipate = () => {
  const { t } = useTranslation()

  return (
    <div className="container-fluid py-6" style={{ background: '#F3FAF5' }}>
      <div className="container">
        <div className="row" style={{}}>
          <div className="col-12 col-md-6 mx-auto text-center">
            <h4 style={{ fontSize: '2rem' }}>{t('howToParticipate.headline')}</h4>
          </div>
        </div>
        <div className="row mt-5" style={{}}>
          <div className="col-12 col-md-4 text-center px-5 p-md-5">
            <div className="my-4">
              <NumberIcon number={1} size={30} fontSize={14}></NumberIcon>
            </div>
            <h5 className="fw-bold">{t('howToParticipate.subheadline1')}</h5>
            <Clock label={t('howToParticipate.watchIcon1')}></Clock>
            <p>{t('howToParticipate.text1')}</p>
            <div className="d-block d-md-none my-3">
              <VerticalDots></VerticalDots>
            </div>
          </div>

          <div className="col-12 col-md-4 text-center px-5 p-md-5">
            <div className="my-4">
              <NumberIcon number={2} size={30} fontSize={14}></NumberIcon>
            </div>
            <h5 className="fw-bold">{t('howToParticipate.subheadline2')}</h5>
            <Clock label={t('howToParticipate.watchIcon2')}></Clock>
            <div>
              <p>{t('howToParticipate.text2')}</p>
            </div>
            <div className="d-block d-md-none my-3">
              <VerticalDots></VerticalDots>
            </div>
          </div>

          <div className="col-12 col-md-4 text-center px-5 p-md-5">
            <div className="my-4">
              <NumberIcon number={3} size={30} fontSize={14}></NumberIcon>
            </div>
            <h5 className="fw-bold">{t('howToParticipate.subheadline3')}</h5>
            <div>
              <p>{t('howToParticipate.text3')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const NumberIcon = ({ number, size, fontSize }) => {
  return (
    <div className="d-flex align-items-center justify-content-center">
      <Circle size={size} radius={size / 2} strokeWidth={0}></Circle>
      <div className="position-absolute text-center" style={{ color: 'white', fontSize: fontSize }}>
        {number}
      </div>
    </div>
  )
}

const Clock = ({ label }) => {
  return (
    <div className="d-flex align-items-center justify-content-center">
      <ClockSvg></ClockSvg>
      <div className="mx-2">{label}</div>
    </div>
  )
}

const Circle = ({ size, radius, strokeWidth }) => {
  return (
    <svg width={size} height={size}>
      <circle cx={radius} cy={radius} r={radius - strokeWidth} stroke="black" strokeWidth={strokeWidth} fill="black" />
    </svg>
  )
}

/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 31/08/2022.
 */
import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'
import ClockSvg from '../assets/clock.svg'
import VerticalDots from '../assets/vertical-dots.svg'
import { useMediaQuery } from 'react-responsive'
import { StaticImage } from 'gatsby-plugin-image'
import video8g from '../images/video.mp4'

export const WhatOurParticipantsSays = () => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ maxWidth: 767 })

  return isMobile ? (
    <div></div>
  ) : (
    <div className="d-flex align-items-center justify-content-center position-relative ">
      <video src={video8g} autoPlay loop muted style={{ width: '100%', height: '100%' }} />
      {/*
      <StaticImage
        src="../images/whatOurParticipantSaysAboutTheStudy.png"
        alt="map"
        placeholder="blurred"
        layout="constrained"
      />
      */}
      <div className="position-absolute text-start start-0 end-0 ps-4 ps-sm-4 ps-md-4 ps-lg-5">
        <div className="row" style={{}}>
          <div className="col-12 text-center">
            <h4 style={{ fontSize: '1.25rem', color: 'white' }}>{t('whatOurParticipantSaysAboutTheStudy.title')}</h4>
          </div>
        </div>
        <br />
        <div className="row" style={{}}>
          <div className="col-12 text-center">
            <h2
              style={{ fontSize: '2rem', color: 'white' }}
              dangerouslySetInnerHTML={{ __html: t('whatOurParticipantSaysAboutTheStudy.text1') }}
            ></h2>
          </div>
        </div>
      </div>
    </div>
  )
}

const NumberIcon = ({ number, size, fontSize }) => {
  return (
    <div className="d-flex align-items-center justify-content-center">
      <Circle size={size} radius={size / 2} strokeWidth={0}></Circle>
      <div className="position-absolute text-center" style={{ color: 'white', fontSize: fontSize }}>
        {number}
      </div>
    </div>
  )
}

const Clock = ({ label }) => {
  return (
    <div className="d-flex align-items-center justify-content-center">
      <ClockSvg></ClockSvg>
      <div className="mx-2">{label}</div>
    </div>
  )
}

const Circle = ({ size, radius, strokeWidth }) => {
  return (
    <svg width={size} height={size}>
      <circle cx={radius} cy={radius} r={radius - strokeWidth} stroke="black" strokeWidth={strokeWidth} fill="black" />
    </svg>
  )
}

/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 28/11/2022.
 */
import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import FadeUp from '../animation/FadeUp'
import { StaticImage } from 'gatsby-plugin-image'
import FacebookShareSvg from '../assets/facebook-share.svg'
import EmailShareSvg from '../assets/email-share.svg'
import TwitterShareSvg from '../assets/twitter-share.svg'

type Props = {}

export const ReferFriendsFamily = ({}: Props) => {
  const { t } = useTranslation()

  return (
    <div className="container py-6">
      <div className="row">
        <div className="col-12 col-md-8 order-md-0 order-2">
          <div className="row">
            <h4 className="col-12" dangerouslySetInnerHTML={{ __html: t('referFriendsFamily.headline') }}></h4>
          </div>
          <br />
          <div className="row">
            <p className="col-12">{t('referFriendsFamily.text1')}</p>
          </div>
          <br />
          <div className="row">
            <p className="col-12">{t('referFriendsFamily.text2')}</p>
          </div>
          <br />
          <div className="row">
            <div className="col-12">
              <div className="d-flex mb-4">
                <a
                  href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fstrides.studiesandme.com%2F&utm_source=referral&utm_medium=STRIDES&utm_campaign=landingpage&utm_term=facebook"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FacebookShareSvg />
                </a>
                <a
                  href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fstrides.studiesandme.com%2F&utm_source=referral&utm_medium=STRIDES&utm_campaign=landingpage&utm_term=twitter"
                  target="_blank"
                  rel="noreferrer"
                >
                  <TwitterShareSvg />
                </a>
                <a href="mailto:?subject=There%20is%20a%20new%20OA%20study%2C%20check%20out%20it%20here&cc=&bcc=&body=https%3A%2F%2Fstrides.studiesandme.com%2F&utm_source=referral&utm_medium=STRIDES&utm_campaign=landingpage&utm_term=email">
                  <EmailShareSvg />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4 h-100 order-md-2 order-0">
          <div className="d-flex justify-content-center align-items-center" style={{ marginBottom: '25px' }}>
            <StaticImage
              className="h-100 w-100"
              objectFit="contain"
              src="../images/family.png"
              objectPosition="50% 50%"
              alt="studies and me"
              quality={100}
              placeholder="none"
            ></StaticImage>
            <br />
          </div>
        </div>
      </div>
    </div>
  )
}

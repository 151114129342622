/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 28/11/2022.
 */
import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import FadeUp from '../animation/FadeUp'
import { StaticImage } from 'gatsby-plugin-image'
import { useMediaQuery } from 'react-responsive'

type Props = {}

export const DoYouHaveKneeOsteoarthritis = () => {
  const { t } = useTranslation()

  return (
    <div className="container py-6">
      <div className="row">
        <div className="col-12 col-md-4 h-100">
          <div className="d-flex justify-content-center align-items-center" style={{ marginBottom: '25px' }}>
            <StaticImage
              className="h-100 w-100"
              objectFit="contain"
              src="../images/osteoarthritis.png"
              objectPosition="50% 50%"
              alt="studies and me"
              quality={100}
              placeholder="none"
            ></StaticImage>
          </div>
        </div>
        <div className="col-12 col-md-8">
          <div className="row">
            <h4 className="col-12">{t('doYouHaveKneeOsteoarthritis.title')}</h4>
          </div>
          <div className="row">
            <p className="col-12">{t('doYouHaveKneeOsteoarthritis.text1')}</p>
          </div>
          <br />
          <div className="row">
            <p className="col-12">{t('doYouHaveKneeOsteoarthritis.text2')}</p>
          </div>
          <br />
          <div className="row">
            <p className="col-12">{t('doYouHaveKneeOsteoarthritis.text3')}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

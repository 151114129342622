import React, { useEffect, useRef, useState } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/seo'

import { BottomDrawerSheet } from '../components/drawer/BottomDrawerSheet'

import { dataStore } from '../store/dataStore'
import URLSearchParams from '@ungap/url-search-params'
import { setUtm } from '../utils/utm'
import { WhatsInItForYou } from '../components/WhatsInItForYou'

import ImageHeaderStrides from '../components/header/ImageHeaderStrides'
import { FactsAboutTheStudy } from '../components/FactsAboutTheStudy'
import { WhatIsStudyAbout } from '../components/WhatIsStudyAbout'
//import { WhoWeAreLookingFor } from '../components/WhoWeAreLookingFor'
import { Support } from '../components/Support'
import { HowToParticipate } from '../components/HowToParticipate'
import { JoinTheStudyAtYourConvenience } from '../components/JoinTheStudyAtYourConvenience'
import { WouldYouLikeToKnowMore } from '../components/WouldYouLikeToKnowMore'

import { ReferFriendsFamily } from '../components/ReferFriendsFamily'
import { DoYouHaveKneeOsteoarthritis } from '../components/DoYouHaveKneeOsteoarthritis'
import { WhatOurParticipantsSays } from '../components/WhatOurParticipantsSays'
import { NotRecruitingNotice } from '../components/NotRecruitingNotice'

const IndexPage = (props) => {
  // should give the same output as the useTranslation hook
  // const language = props.data.locales.edges[0].node.language

  const location = props.location
  //const { t, i18n } = useTranslation()

  const hasQuery = location.search
  setUtm(hasQuery)

  const getLocation = dataStore((state) => state.getLocation)
  const [showDrawer, setShowDrawer] = useState(false)

  const readMoreRef = useRef()
  const signupRef = useRef()

  useEffect(() => {
    const queryString = new URLSearchParams(location.search)

    const testIp = queryString.get('testip') || ''

    getLocation(testIp)
  }, [getLocation, location.search])

  const openDrawer = () => {
    setShowDrawer(true)
  }

  const closeDrawer = (value) => {
    setShowDrawer(value)
  }

  const scrollToSignup = (event) => {
    ///window.scrollTo({ behavior: 'smooth', top: designRef.current.offsetTop })
    signupRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <Layout location={location} title={'Strides'}>
      <Seo title="Want to participate?" />

      <div className="container-fluid" style={{ background: '#D45B5B' }}>
        <NotRecruitingNotice></NotRecruitingNotice>
      </div>

      <div className="container-fluid">
        <ImageHeaderStrides readMoreRef={readMoreRef}></ImageHeaderStrides>
      </div>

      <div ref={readMoreRef} className="container-fluid py-7" style={{}}>
        <WhatsInItForYou></WhatsInItForYou>
      </div>

      <div className="container-fluid py-5" style={{ background: '#D3E2DF' }}>
        <DoYouHaveKneeOsteoarthritis></DoYouHaveKneeOsteoarthritis>
      </div>

      <div ref={signupRef} className="container-fluid pb-5" style={{ background: '#EDF7EF' }}>
        <WhatIsStudyAbout></WhatIsStudyAbout>
      </div>

      <div>
        <WhatOurParticipantsSays></WhatOurParticipantsSays>
      </div>

      <div className="container-fluid mt-md-6 py-7 pt-md-5">
        <JoinTheStudyAtYourConvenience></JoinTheStudyAtYourConvenience>
      </div>

      <div className="container-fluid py-5" style={{ background: '#EBF2F0' }}>
        <ReferFriendsFamily></ReferFriendsFamily>
      </div>

      <div className="container-fluid py-5" style={{ background: '#F4FAF5' }}>
        <HowToParticipate></HowToParticipate>
      </div>

      <div className="container-fluid">
        <WouldYouLikeToKnowMore></WouldYouLikeToKnowMore>
      </div>

      {/*<SignupSteps></SignupSteps>*/}
      {/* <WhoWeAreLookingFor></WhoWeAreLookingFor> */}
      {/*<FactsAboutTheStudy scrollToSignup={scrollToSignup}></FactsAboutTheStudy>*/}

      {/*<Support></Support>*/}

      <BottomDrawerSheet openDrawer={showDrawer} closeFunc={closeDrawer}></BottomDrawerSheet>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 28/11/2022.
 */
import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import FadeUp from '../animation/FadeUp'
import { StaticImage } from 'gatsby-plugin-image'
import FacebookShareSvg from '../assets/facebook-share.svg'
import EmailShareSvg from '../assets/email-share.svg'
import TwitterShareSvg from '../assets/twitter-share.svg'

type Props = {}

export const NotRecruitingNotice = ({}: Props) => {
  const { t } = useTranslation()

  return (
    <div className="container py-6">
      <div className="row">
        <div className="col-12">
          <div className="row">
            <h4 className="col-12 text-center" style={{ color: 'white' }}>
              {t('studyStatus.headline')}
            </h4>
          </div>
          <br />
          <div className="row">
            <p className="col-12 text-center" style={{ color: 'white' }}>
              {t('studyStatus.subheadline')}
            </p>
          </div>
          <div className="row pt-5" style={{}}>
            <div className="col">
              <div className="d-flex w-100 w-lg-auto justify-content-center align-items-center">
                <a
                  href={'https://donatello.studiesandme.com'}
                  className="btn bg-white btn-shadow px-4 py-3 px-md-6 mb-3 mb-md-0 me-md-4"
                >
                  {t('studyStatus.signupButton')} {/* IS MOBILE*/}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
